@mixin button-loader($spinnerPath) {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(#{$spinnerPath}) 50% 50% transparent
      no-repeat;
    background-size: auto 55px;
    z-index: -5;
    opacity: 0;
    transition: opacity linear 250ms;
  }

  &.button--loading{
    color: transparent;
    
    &:hover {
      color: transparent;
    }

    &::after {
      z-index: 5;
      opacity: 1;
    }
  }
}

@mixin main-button {
  position: relative;
  background-image: linear-gradient(92.34deg, #ff511e 0.14%, #ff29ae 50%, #ff511e);
  padding: 16px 36px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $white-100;
  text-align: center;
  cursor: pointer;
  transition: background-position 0.25s linear, color 0.25s linear;
  background-size: 300% 300%;
  outline: none;
  min-width: 180px;
  border: 1px solid transparent;

  @include respond-to(tablet) {
    padding: 14px 30px;
  }

  @include respond-to(phone) {
    padding: 12px 24px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
  }

  &:hover {
    background-position: 100% 100%;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;

    &:hover {
      background-position: 0 0;
    }
  }

  @include button-loader('/assets/form/button_spinner_white.svg');
}

@mixin transparent-button {
  background-color: transparent;
  padding: 16px 32px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $white-100;
  text-align: center;
  cursor: pointer;
  border: 2px solid $white-100;
  transition: background-color 0.25s linear, color 0.25s linear;

  @include respond-to(tablet) {
    padding: 14px 30px;
  }

  @include respond-to(phone) {
    padding: 12px 24px;
    font-size: 14px;
    line-height: 18px;
  }

  .fixed &,
  .header__mobile &,
  .er_header_fixed & {
    color: $black;
    border: 2px solid $black;
  }

  &:hover {
    background-color: $white-100;
    color: $black;

    .fixed &,
    .header__mobile & {
      background-color: $black;
      color: $white-100;
    }
  }
}

@mixin white-button {
  position: relative;
  background-color: $white-100;
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $blue;
  cursor: pointer;
  transition: background-color 0.25s linear, color 0.25s linear, border-color 0.25s linear, color 0.25s linear;

  @include respond-to(tablet) {
    padding: 14px;
  }

  @include respond-to(phone) {
    padding: 12px;
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    border-color: $white-100;
    background-color: transparent;
    color: $white-100;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;

    &:hover {
      background-color: $white-100;
      border-color: transparent;
      color: $blue;
    }
  }

  @include button-loader('/assets/form/button_spinner_blue.svg');
}

@mixin grey-button {
  position: relative;
  background: $white-100;
  border-radius: 100px;
  padding: 12px 24px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $light-grey;
  cursor: pointer;
  transition: background 0.25s linear, color 0.25s linear;

  @include respond-to(tablet) {
    padding: 10px 22px;
  }

  @include respond-to(phone) {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: 100px;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: $border;
    transition: background 0.25s linear;
    z-index: -1;
  }

  &:not(&_active):hover {
    background: $white-grey;
  }

  &_active {
    background: linear-gradient(93.34deg, #ff511e 3.42%, #ff29ae 98.59%);
    color: $white-100;
    transition: background 0.25s linear, background-position 0.25s linear, color 0.25s linear;
    background-size: 150% 150%;

    &:hover {
      background-position: 100% 100%;

      &::before {
        background: transparent;
      }
    }

    &::before {
      background: transparent;
    }
  }
}

@mixin pagination {
  position: relative;
  margin-top: 17px;
  display: flex;
  justify-content: center;

  @include respond-to(tablet) {
    margin-top: 8px;
  }

  @include respond-to(phone) {
    margin-top: 4px;
  }

  span {
    position: relative;
    opacity: 1;
    width: auto;
    height: auto;
    background-color: transparent;
    border-radius: 0;

    &::before {
      content: '';
      position: relative;
      display: inline-flex;
      width: 60px;
      height: 4px;
      background-color: $pagination;
      border-radius: 20px;
      margin: 15px 8px;
      transition: background-color 0.25s ease-out;

      @include respond-to(tablet) {
        width: 40px;
        height: 3px;
        margin: 10px 6px;
      }

      @include respond-to(phone) {
        width: 20px;
        height: 2px;
        margin: 8px 4px;
      }

      @include respond-to(mini) {
        width: 10px;
      }
    }

    &:not(:last-child) {
      margin-right: 4px;

      @include respond-to(tablet) {
        margin-right: 2px;
      }
    }

    &.swiper-pagination-bullet-active {
      &::before {
        background-color: $blue;
      }
    }

    &:not(.swiper-pagination-bullet-active):hover {
      &::before {
        background-color: $light-grey;
      }
    }
  }
}
